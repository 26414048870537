import {
  Accessor,
  Index,
  Match,
  Resource,
  Setter,
  Show,
  Switch,
  createEffect,
  createMemo,
  createSignal,
  onMount,
} from "solid-js";
import { placeholderCard } from "~/assets/assets";
import HubbleImage from "~/widgets/hubble_image";
import { DottedLoader } from "~/widgets/loader";
import { BrandDetailDenominationModal } from "./brand_detail_denomination_modal";
import { DenominationDetailsModal } from "./denominations_details_modal";
import { CustomDenominationField } from "./custom_denomination_field";
import { PhosphorIcon } from "~/widgets/icons";
import { toRupees } from "~/utils/number";
import { CartProduct, useSquidContext } from "~/context/squid_context";
import { VoucherProduct } from "~/server/types/brand";
import { useModal } from "../modal";
import { clientRepo } from "~/server/apis/client_repo";
import { isMobileDevice } from "~/utils/detectDevice";
import { SquidProductWithName } from "~/server/types/order";
import { ThreeDotLoader } from "~/widgets/button";
import { zIndexes } from "~/z_indexes";

type DenominationDetailsContentProps = {
  fullScreen?: boolean;
  brandDetails: Resource<VoucherProduct>;
  brandKey: string;
  discountPercentage: number;
  addToCart: (newProduct: CartProduct) => void;
  addProductsToCart: (products: SquidProductWithName[]) => void;
  simulateErrorMessage: Accessor<string | undefined>;
  loadingSimulateData: Accessor<boolean>;
  isInDetailedView?: boolean;
  denominationDetails?: { quantity: number; denomination: number }[];
  denominationDetailsSetter?: (
    details: { quantity: number; denomination: number }[]
  ) => void;
  closeModal?: () => void;
  openModal?: (component: () => any, className?: string) => void;
  setIsFullScreenBrandDetailsDenomitionsModalOpen?: Setter<boolean>;
  setCurrentModalProps?: (props: any) => void;
};

export function DenominationDetailsContent(
  props: DenominationDetailsContentProps
) {
  const [showCustomDenomanination, setShowCustomDenomination] =
    createSignal(false);
  let customDenominationInput: HTMLInputElement | undefined;
  const inputMap = new Map<string, HTMLInputElement>();

  const customAmountDefaultDenominationsList = createMemo(() => {
    const denominations = [
      100, 250, 500, 750, 1000, 1500, 2000, 2500, 5000, 10000,
    ];
    return denominations.filter(
      (denomination) =>
        denomination >=
          (props.brandDetails()?.amountConditions?.minAmount ?? 0) &&
        denomination <= props.brandDetails()?.amountConditions?.maxAmount!
    );
  });

  const denominationList = createMemo(() => {
    if (props.brandDetails.state !== "ready") return [];
    return (
      props.brandDetails()?.amountConditions?.denominations ??
      customAmountDefaultDenominationsList()
    ).map((denomination: any) => {
      return {
        denomination,
        quantity: 0,
      };
    });
  });

  const [denominationsWithQuantity, setDenominationsWithQuantity] =
    createSignal<{ denomination: number; quantity: number }[]>([]);

  createEffect(() => {
    if (denominationsWithQuantity()) {
      if (props.denominationDetailsSetter) {
        props.denominationDetailsSetter(denominationsWithQuantity());
      }
    }
  });
  createEffect(() => {
    if (props.brandDetails.state === "ready") {
      if (props.denominationDetails) {
        setDenominationsWithQuantity(props.denominationDetails);
      } else {
        setDenominationsWithQuantity(denominationList());
      }
      setShowCustomDenomination(
        !(props.brandDetails()?.amountConditions?.denominations != undefined)
      );
    }
  });

  const handleQuantityChange = (denomination: number, newQuantity: number) => {
    setShowSimulateError(false);
    setDenominationsWithQuantity((prev: any[]) =>
      prev.map((item: any) =>
        item.denomination === denomination
          ? {
              ...item,
              quantity: Math.max(0, newQuantity),
            }
          : item
      )
    );
  };

  const updateCustomQuantity = (amount: number, quantity: number) => {
    let currentMap = [...denominationsWithQuantity()];
    if (denominationList().find((item) => item.denomination === amount)) {
      return handleQuantityChange(amount, quantity);
    }
    if (quantity === 0) {
      currentMap = currentMap.filter((item) => item.denomination !== amount);
    } else {
      currentMap.push({
        denomination: amount,
        quantity: quantity,
      });
    }
    setDenominationsWithQuantity(currentMap);
  };

  const [customAmount, setCustomAmount] = createSignal(0);
  const [showCustomAmountError, setShowCustomAmountError] = createSignal(false);
  const [customAmountFieldActive, setCustomAmountFieldActive] =
    createSignal(false);

  const handleCustomAmountChange = (event: Event) => {
    const input = event.target as HTMLInputElement;
    const value = parseInt(input.value, 10);
    const amountConditions = props.brandDetails()?.amountConditions;

    if (!isNaN(value)) {
      if (value != customAmount()) {
        if (
          value >= (amountConditions?.minAmount ?? 0) &&
          value <= amountConditions?.maxAmount!
        ) {
          updateCustomQuantity(customAmount(), 0);
          setCustomAmount(value);
          const selectedDenomination = denominationsWithQuantity().find(
            (item) => item.denomination == value
          );
          if (selectedDenomination) {
            if (selectedDenomination?.quantity === 0) {
              handleQuantityChange(customAmount(), 1);
            }
          } else {
            updateCustomQuantity(customAmount(), 1);
          }
          setShowCustomAmountError(false);
        } else {
          setShowCustomAmountError(true);
          updateCustomQuantity(customAmount(), 0);
          setCustomAmount(0);
        }
      }
    } else if (input.value === "") {
      updateCustomQuantity(customAmount(), 0);
      setCustomAmount(0);
      setShowCustomAmountError(false);
    }
  };

  const handleInputChange = (denomination: number, event: Event) => {
    const input = event.target as HTMLInputElement;
    const value = parseInt(input.value, 10);

    if (!isNaN(value)) {
      handleQuantityChange(denomination, value);
    } else if (input.value === "") {
      handleQuantityChange(denomination, 0);
    }
  };

  const calculateTotalAmount = () => {
    return denominationsWithQuantity().reduce(
      (total: number, item: { denomination: number; quantity: number }) => {
        return total + item.denomination * item.quantity;
      },
      0
    );
  };

  const calculateDiscount = (totalAmount: number) => {
    return (totalAmount * props.discountPercentage) / 100;
  };

  const totalAmount = createMemo(() => calculateTotalAmount());
  const discountAmount = createMemo(() => calculateDiscount(totalAmount()));
  const finalAmount = createMemo(() => totalAmount() - discountAmount());

  const disableAddToCart = createMemo(() =>
    denominationsWithQuantity().every(
      (item: { quantity: number }) => item.quantity === 0
    )
  );

  const getDenominationQuantity = (denomination: number) => {
    return (
      denominationsWithQuantity().find(
        (item: { denomination: number; quantity: number }) =>
          item.denomination === denomination
      )?.quantity ?? 0
    );
  };

  const { openModal, closeModal } = useModal()!;

  const [showTnCToolTip, setShowTnCToolTip] = createSignal(false);

  const [simulateAndCloseModal, setSimulateAndCloseModal] = createSignal(false);
  const [showSimulateError, setShowSimulateError] = createSignal(false);

  const checkAndShowTnCToolTip = async () => {
    if (props.isInDetailedView) {
      return;
    }
    const showTnCToolTipCountKey = "showTnCToolTipCount";
    const maxCount = 1;

    let userConfig = await clientRepo.getUserConfig();

    const count = userConfig[showTnCToolTipCountKey] ?? 0;

    if (count && parseInt(count, 10) >= maxCount) {
      return;
    }
    userConfig[showTnCToolTipCountKey] = count + 1;
    clientRepo.updateUserConfig(userConfig);
    setTimeout(() => {
      setShowTnCToolTip(true);
    }, 1000);
  };

  onMount(() => {
    checkAndShowTnCToolTip();
  });

  const handleAddToCart = () => {
    const selectedDenominations = denominationsWithQuantity().filter(
      (item: { quantity: number }) => item.quantity > 0
    );

    const product: SquidProductWithName = {
      productName: props.brandDetails()?.name!,
      productId: props.brandDetails()?.externalId!,
      logoUrl: props.brandDetails()?.voucherProductMetadata?.iconImageUrl!,
      amount: totalAmount(),
      denominationDetails: selectedDenominations,
    };
    props.addProductsToCart([product]);
    setSimulateAndCloseModal(true); // listens to simulateLoading and error message from context to close modal
  };

  createEffect(() => {
    if (simulateAndCloseModal()) {
      console.log(
        "simulateAndCloseModal, loadingSimulateData",
        props.loadingSimulateData(),
        " simulateErrorMessage",
        props.simulateErrorMessage()
      );
      if (!props.loadingSimulateData() && !props.simulateErrorMessage()) {
        props.closeModal?.();
        setShowSimulateError(false);
      } else if (!props.loadingSimulateData() && props.simulateErrorMessage()) {
        setShowSimulateError(true);
        setSimulateAndCloseModal(false);
      }
    }
  });

  return (
    <Switch>
      <Match when={!props.brandDetails()}>
        <div class="flex h-full w-full items-center justify-center">
          <DottedLoader
            color="#FFC0CB"
            from="denomination details content - switch !branddetails"
          />
        </div>
      </Match>
      <Match when={props.brandDetails()}>
        <div
          class="flex w-full flex-col gap-5 rounded-[20px]"
          classList={{
            "pt-0 h-full": props.fullScreen,
            "pt-8": !props.fullScreen,
          }}
        >
          <div class="flex flex-col items-center justify-center gap-2">
            <HubbleImage
              src={props.brandDetails()?.voucherProductMetadata?.iconImageUrl!}
              class="inline-block h-[120px] w-[108px] rounded-xl sm:h-[88px] sm:w-[78px]"
              alt={`${props.brandDetails()?.name} logo image`}
              errorImage={placeholderCard}
            />
            <div class="flex flex-col items-center justify-center">
              <p class="text-h4 text-textDark">
                {props.brandDetails()?.name} gift card
              </p>
              <div
                class="mt-0.5 cursor-pointer text-medium text-textNormal underline"
                onClick={() => {
                  if (!isMobileDevice()) {
                    props.closeModal?.();
                  }
                  if (props.isInDetailedView) {
                    props.openModal?.(() => {
                      return (
                        <DenominationDetailsModal
                          brandKey={props.brandKey}
                          discountPercentage={props.discountPercentage}
                          addToCart={props.addToCart}
                          addProductsToCart={props.addProductsToCart}
                          simulateErrorMessage={props.simulateErrorMessage}
                          loadingSimulateData={props.loadingSimulateData}
                          denominationDetails={denominationsWithQuantity()}
                        />
                      );
                    }, "lg:w-[360px] lg:h-[480px] h-fit rounded-[20px]");
                  } else {
                    if (!isMobileDevice()) {
                      props.closeModal?.();
                      props.openModal?.(() => {
                        return (
                          <BrandDetailDenominationModal
                            brandKey={props.brandKey}
                            discountPercentage={props.discountPercentage}
                            addToCart={props.addToCart}
                            addProductsToCart={props.addProductsToCart}
                            simulateErrorMessage={props.simulateErrorMessage}
                            loadingSimulateData={props.loadingSimulateData}
                            denominationDetails={denominationsWithQuantity()}
                            setIsFullScreenBrandDetailsDenomitionsModalOpen={
                              props.setIsFullScreenBrandDetailsDenomitionsModalOpen!
                            }
                          />
                        );
                      }, "lg:max-w-[900px] lg:w-[900px] lg:max-h-[714px] lg:h-[714px] h-fit rounded-[28px]");
                    } else {
                      const modalProps = {
                        brandKey: props.brandKey,
                        discountPercentage: props.discountPercentage,
                      };
                      props.setCurrentModalProps?.(modalProps);
                      props.setIsFullScreenBrandDetailsDenomitionsModalOpen?.(
                        true
                      );
                    }
                  }
                }}
              >
                <Show
                  when={!props.isInDetailedView}
                  fallback={<div>Hide details</div>}
                >
                  <div class="relative flex flex-col">
                    <div class="hidden sm:flex">How to use & T&Cs</div>
                    <div class="sm:hidden">Details</div>

                    <div
                      class={`shadow-lg absolute bottom-full z-[${zIndexes.squidToolTip}] w-[129px] translate-y-[100px] animate-expand cursor-default rounded-lg bg-blue-600 p-3 text-center text-white ${showTnCToolTip() ? "h-fit" : "hidden h-0"}`}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div class="absolute bottom-full left-1/2 -translate-x-1/2 border-8 border-transparent border-b-blue-600" />
                      <div
                        class="absolute right-0 top-0 h-4 w-4 cursor-pointer text-textWhite opacity-40"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowTnCToolTip(false);
                        }}
                      >
                        ✕
                      </div>
                      More details about this card
                    </div>
                  </div>
                </Show>
              </div>
            </div>
          </div>
          <div
            class="no-scrollbar flex flex-col gap-3 sm:overflow-y-auto"
            classList={{
              "px-1 w-full h-full": props.fullScreen,
              "m-auto": !props.fullScreen,
            }}
          >
            <CustomDenominationField
              customAmount={customAmount}
              customDenominationInput={customDenominationInput}
              customAmountFieldActive={customAmountFieldActive}
              showCustomAmountError={showCustomAmountError}
              showCustomDenomanination={showCustomDenomanination}
              handleCustomAmountChange={handleCustomAmountChange}
              handleQuantityChange={handleQuantityChange}
              handleInputChange={handleInputChange}
              getDenominationQuantity={getDenominationQuantity}
              setCustomAmountFieldActive={setCustomAmountFieldActive}
              brandDetails={props.brandDetails}
            />
            <Index each={denominationsWithQuantity()}>
              {(denomination, index) => {
                // ignores adding custom amount entry as a denomination as already exists above
                if (denomination().denomination == customAmount()) {
                  return null;
                }
                return (
                  <div class="flex w-full items-center justify-between rounded-xl bg-gray-100 py-[8.5px] pl-3 pr-2 sm:w-[316px]">
                    <p class="text-mediumBold text-textDark">
                      ₹{denomination().denomination}
                    </p>
                    <div class="inline-block rounded-lg bg-basePrimaryDark">
                      <div class="flex items-center">
                        <button
                          type="button"
                          class="text-sm shadow-sm inline-flex size-7 items-center justify-center gap-x-2 rounded-md  font-medium text-white focus:outline-none disabled:pointer-events-none disabled:opacity-50"
                          onClick={() => {
                            if (
                              inputMap.get(`${denomination().denomination}`) &&
                              !isMobileDevice()
                            ) {
                              inputMap
                                .get(`${denomination().denomination}`)
                                ?.focus();
                            }
                            handleQuantityChange(
                              denomination().denomination,
                              denomination().quantity - 1
                            );
                          }}
                          disabled={denomination().quantity === 0}
                        >
                          <PhosphorIcon
                            name="minus"
                            fontSize={12}
                            size="bold"
                            class="text-white"
                          />
                        </button>
                        <input
                          ref={(el) =>
                            inputMap.set(`${denomination().denomination}`, el)
                          }
                          class="[&::-webkit-outer-spin-button] :appearance-none text w-6 scale-75 transform border-0  bg-transparent p-0 text-center text-f16Bold  text-white hover:underline focus:underline
                          focus:ring-0 sm:scale-100 sm:text-f12Bold [&::-webkit-inner-spin-button]:appearance-none"
                          type="number"
                          value={denomination().quantity}
                          onInput={(e) =>
                            handleInputChange(denomination().denomination, e)
                          }
                        />
                        <button
                          type="button"
                          class="text-sm shadow-sm inline-flex size-7 items-center justify-center gap-x-2 rounded-md font-medium text-white focus:outline-none disabled:pointer-events-none disabled:opacity-50"
                          onClick={() => {
                            if (
                              inputMap.get(`${denomination().denomination}`) &&
                              !isMobileDevice()
                            ) {
                              inputMap
                                .get(`${denomination().denomination}`)
                                ?.focus();
                            }
                            handleQuantityChange(
                              denomination().denomination,
                              denomination().quantity + 1
                            );
                          }}
                        >
                          <PhosphorIcon
                            name="plus"
                            fontSize={12}
                            size="bold"
                            class="text-white"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              }}
            </Index>
          </div>
          <div class="sticky bottom-0 flex flex-col gap-3 border-t border-baseTertiaryDark bg-white px-4 pb-4 pt-3">
            <Switch>
              <Match when={props.simulateErrorMessage() && showSimulateError()}>
                <p class="text-center text-subtitleSmall text-errorDark">
                  {props.simulateErrorMessage()}
                </p>
              </Match>
              <Match when={!props.discountPercentage && !disableAddToCart()}>
                <p class="text-center text-subtitleSmall text-textDark">
                  You pay {toRupees(totalAmount())}
                </p>
              </Match>
              <Match when={disableAddToCart()}>
                <p class="text-center text-medium text-textDark">
                  Please add gift card denominations to buy
                </p>
              </Match>
              <Match when={!disableAddToCart()}>
                <div class="flex justify-between">
                  <p class="text-center text-subtitleSmall text-textDark">
                    You pay{" "}
                    <span class="text-successDark">
                      {toRupees(finalAmount())}
                    </span>{" "}
                    <span class="text-medium text-textNormal line-through">
                      {toRupees(totalAmount())}
                    </span>
                  </p>
                  <p class="text-subtitleSmall text-successDark">
                    Instant {props.discountPercentage}% off
                  </p>
                </div>
              </Match>
            </Switch>
            <button
              class="flex w-full items-center justify-center gap-1 rounded-lg bg-basePrimaryDark px-4 py-[15px] text-subtitleSmall text-white disabled:bg-baseSecondaryLight sm:py-2.5"
              disabled={disableAddToCart() || showSimulateError()}
              onClick={handleAddToCart}
            >
              <Show
                when={!props.loadingSimulateData()}
                fallback={
                  <div class="py-1.5">
                    <ThreeDotLoader color="#FFF" />
                  </div>
                }
              >
                <PhosphorIcon name="shopping-cart-simple" />
                Add to cart
              </Show>
            </button>
          </div>
        </div>
      </Match>
    </Switch>
  );
}
