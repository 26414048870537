import { CartProduct } from "~/context/squid_context";
import { BrandDenominationDetailModal } from "./brand_detail_modal";
import { DenominationDetailsModal } from "./denominations_details_modal";
import { Accessor, createSignal, Setter } from "solid-js";
import { SquidProductWithName } from "~/server/types/order";

export function BrandDetailDenominationModal(props: {
  brandKey: string;
  discountPercentage: number;
  addToCart: (newProduct: CartProduct) => void;
  addProductsToCart: (products: SquidProductWithName[]) => void;
  simulateErrorMessage: Accessor<string | undefined>;
  loadingSimulateData: Accessor<boolean>;
  denominationDetails?: { quantity: number; denomination: number }[];
  setIsFullScreenBrandDetailsDenomitionsModalOpen: Setter<boolean>;
}) {
  const [denominationDetails, setDenominationDetails] = createSignal<
    { quantity: number; denomination: number }[]
  >([]);

  return (
    <div class="relative flex h-[700px] max-h-[700px] w-full overflow-y-hidden bg-white">
      <div class="no-scrollbar  w-[514px] max-w-[514px]  overflow-y-scroll rounded-2xl border">
        <BrandDenominationDetailModal
          brandKey={props.brandKey}
          isInDetailedView={true}
          discountPercentage={props.discountPercentage}
          addToCart={props.addToCart}
          addProductsToCart={props.addProductsToCart}
          simulateErrorMessage={props.simulateErrorMessage}
          loadingSimulateData={props.loadingSimulateData}
          denominationDetails={props.denominationDetails}
          denominationDetailsAccessor={
            denominationDetails().length > 0 ? denominationDetails : undefined
          }
          fullScreen={false}
        />
      </div>
      <div class="no-scrollbar absolute right-0 top-0 h-[480px] w-[360px] overflow-y-scroll rounded-[20px] border bg-white">
        <DenominationDetailsModal
          discountPercentage={props.discountPercentage}
          addToCart={props.addToCart}
          addProductsToCart={props.addProductsToCart}
          simulateErrorMessage={props.simulateErrorMessage}
          loadingSimulateData={props.loadingSimulateData}
          brandKey={props.brandKey}
          isInDetailedView={true}
          denominationDetails={props.denominationDetails}
          denominationDetailsSetter={setDenominationDetails}
        />
      </div>
    </div>
  );
}
