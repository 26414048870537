import { Accessor, Resource, Setter, Show } from "solid-js";
import { VoucherProduct } from "~/server/types/brand";
import { PhosphorIcon } from "~/widgets/icons";

export function CustomDenominationField(props: {
  showCustomDenomanination: Accessor<boolean>;
  showCustomAmountError: Accessor<boolean>;
  customAmountFieldActive: Accessor<boolean>;
  customAmount: Accessor<number>;
  customDenominationInput: HTMLInputElement | undefined;
  handleCustomAmountChange: (event: Event) => void;
  setCustomAmountFieldActive: Setter<boolean>;
  getDenominationQuantity: (denomination: number) => number;
  handleQuantityChange: (denomination: number, newQuantity: number) => void;
  handleInputChange: (denomination: number, event: Event) => void;
  brandDetails: () => VoucherProduct | undefined;
}) {
  return (
    <div>
      <Show when={props.showCustomDenomanination()}>
        <div
          class={`flex w-full cursor-pointer items-center justify-between rounded-xl border bg-gray-100 py-[6.5px] pl-3 pr-2 sm:w-[316px] ${props.showCustomAmountError() ? " border-errorDark" : props.customAmountFieldActive() || props.customAmount() > 0 ? "border-basePrimaryDark" : "border-gray-100"}`}
          onClick={() => {
            if (props.customDenominationInput) {
              props.customDenominationInput.focus();
            }
          }}
        >
          <p class="flex h-7 -translate-x-5 scale-[80%] items-center text-f16Bold text-textDark sm:-translate-x-0 sm:scale-100  sm:text-mediumBold  ">
            ₹
            <input
              ref={props.customDenominationInput}
              type="number"
              placeholder="Enter amount"
              class="ml-1 border-0 bg-gray-100 [appearance:textfield] placeholder:text-f16Bold placeholder:text-textNormal sm:scale-100 sm:placeholder:text-medium [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
              onInput={(e) => props.handleCustomAmountChange(e)}
              onFocusIn={() => props.setCustomAmountFieldActive(true)}
              onFocusOut={() => props.setCustomAmountFieldActive(false)}
              value={
                props.customAmount() > 0 ? props.customAmount() : undefined
              }
            />
          </p>
          <Show
            when={props.customAmount() > 0 && !props.showCustomAmountError()}
          >
            <div class="inline-block rounded-lg bg-basePrimaryDark">
              <div class="flex items-center">
                <button
                  type="button"
                  class="text-sm shadow-sm inline-flex size-7 items-center justify-center gap-x-2 rounded-md  font-medium text-white focus:outline-none disabled:pointer-events-none disabled:opacity-50"
                  onClick={() =>
                    props.handleQuantityChange(
                      props.customAmount(),
                      props.getDenominationQuantity(props.customAmount()) - 1
                    )
                  }
                  disabled={
                    props.getDenominationQuantity(props.customAmount()) < 1
                  }
                >
                  <PhosphorIcon
                    name="minus"
                    fontSize={12}
                    size="bold"
                    class="text-white"
                  />
                </button>
                <input
                  onClick={(e) => e.stopPropagation()}
                  class="[&::-webkit-outer-spin-button] :appearance-none w-6 scale-75 border-0 bg-transparent p-0 text-center text-f16Bold text-white hover:underline focus:ring-0 sm:scale-100 sm:text-f12Bold [&::-webkit-inner-spin-button]:appearance-none"
                  type="number"
                  value={props.getDenominationQuantity(props.customAmount())}
                  onInput={(e) =>
                    props.handleInputChange(props.customAmount(), e)
                  }
                />
                <button
                  type="button"
                  class="text-sm shadow-sm inline-flex size-7 items-center justify-center gap-x-2 rounded-md font-medium text-white focus:outline-none disabled:pointer-events-none disabled:opacity-50"
                  onClick={() =>
                    props.handleQuantityChange(
                      props.customAmount(),
                      props.getDenominationQuantity(props.customAmount()) + 1
                    )
                  }
                >
                  <PhosphorIcon
                    name="plus"
                    fontSize={12}
                    size="bold"
                    class="text-white"
                  />
                </button>
              </div>
            </div>
          </Show>
        </div>
      </Show>
      <Show when={props.showCustomAmountError() && props.brandDetails()}>
        <div class="mt-1 text-f12 text-errorDark">
          Please enter an amount between ₹
          {props.brandDetails()?.amountConditions.minAmount} to ₹
          {props.brandDetails()?.amountConditions.maxAmount}
        </div>
      </Show>
    </div>
  );
}
