import { Accessor, createResource, Setter, Suspense } from "solid-js";
import { clientRepo } from "~/server/apis/client_repo";
import { VoucherProduct } from "~/server/types/brand";
import { DottedLoader } from "~/widgets/loader";
import { CartProduct } from "~/context/squid_context";
import { DenominationDetailsContent } from "./denomination_details_content";
import { useModal } from "../modal";
import { SquidProductWithName } from "~/server/types/order";

type DenominationDetailsModalProps = {
  brandKey: string;
  discountPercentage: number;
  addToCart: (newProduct: CartProduct) => void;
  addProductsToCart: (products: SquidProductWithName[]) => void;
  simulateErrorMessage: Accessor<string | undefined>;
  loadingSimulateData: Accessor<boolean>;
  isInDetailedView?: boolean;
  denominationDetails?: { quantity: number; denomination: number }[];
  denominationDetailsSetter?: Setter<
    {
      quantity: number;
      denomination: number;
    }[]
  >;
  setIsFullScreenBrandDetailsDenomitionsModalOpen?: Setter<boolean>;
};

export const DenominationDetailsModal = ({
  discountPercentage,
  addToCart,
  addProductsToCart,
  loadingSimulateData,
  simulateErrorMessage,
  brandKey,
  isInDetailedView = false,
  denominationDetails = undefined,
  denominationDetailsSetter = undefined,
  setIsFullScreenBrandDetailsDenomitionsModalOpen = undefined,
}: DenominationDetailsModalProps) => {
  const [brandDetails] = createResource<VoucherProduct>(async () => {
    return clientRepo.getBrand(brandKey);
  });
  const { openModal, closeModal } = useModal()!;

  return (
    <Suspense fallback={<DottedLoader color="#999" />}>
      <DenominationDetailsContent
        brandDetails={brandDetails}
        brandKey={brandKey}
        discountPercentage={discountPercentage}
        addToCart={addToCart}
        addProductsToCart={addProductsToCart}
        simulateErrorMessage={simulateErrorMessage}
        loadingSimulateData={loadingSimulateData}
        isInDetailedView={isInDetailedView}
        denominationDetails={denominationDetails}
        denominationDetailsSetter={denominationDetailsSetter}
        closeModal={closeModal}
        openModal={openModal}
        setIsFullScreenBrandDetailsDenomitionsModalOpen={
          setIsFullScreenBrandDetailsDenomitionsModalOpen
        }
      />
    </Suspense>
  );
};
