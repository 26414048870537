import {
  Accessor,
  createMemo,
  createResource,
  createSignal,
  For,
  Match,
  onMount,
  Show,
  Switch,
} from "solid-js";
import SolidMarkdown from "solid-markdown";
import { clientRepo } from "~/server/apis/client_repo";
import { getBrandTncData } from "~/server/data/brand_tnc_data";
import { BrandExtraMetadata, VoucherProduct } from "~/server/types/brand";
import { DottedLoader } from "~/widgets/loader";
import {
  collapseIcon,
  hubbleOneHowToUseStep1,
  hubbleOneHowToUseStep2,
  hubbleOneHowToUseStep3,
  hubbleOneHowToUseStep4,
} from "~/assets/assets";
import HubbleImage from "~/widgets/hubble_image";
import { VoucherGenerationBrandCard } from "../brand_l2/voucher_generation_brand_card";
import { PhosphorIcon } from "~/widgets/icons";
import { CartProduct } from "~/context/squid_context";
import { useModal } from "../modal";
import { DenominationDetailsModal } from "./denominations_details_modal";
import { SquidProductWithName } from "~/server/types/order";

type BrandDenominationDetailModalProps = {
  fullScreen?: boolean;
  brandKey: string;
  discountPercentage?: number;
  addToCart?: (newProduct: CartProduct) => void;
  addProductsToCart: (products: SquidProductWithName[]) => void;
  simulateErrorMessage: Accessor<string | undefined>;
  loadingSimulateData: Accessor<boolean>;
  isInDetailedView?: boolean;
  denominationDetails?: { quantity: number; denomination: number }[];
  denominationDetailsAccessor?: Accessor<
    {
      quantity: number;
      denomination: number;
    }[]
  >;
};
type ProcurementBrandDetailModalRouteData = {
  voucherDetail: VoucherProduct;
  tnc: string;
  extraMetadata: BrandExtraMetadata;
};

export function BrandDenominationDetailModal(
  props: BrandDenominationDetailModalProps
) {
  const [routeData] = createResource<ProcurementBrandDetailModalRouteData>(
    async () => {
      const voucherDetail = await clientRepo.getBrand(props.brandKey);
      const tnc = await getBrandTncData(
        voucherDetail.voucherProductMetadata.tncResId
      );
      const extraMetadata = await clientRepo.getBrandExtraMetadata(props.brandKey);
      return { voucherDetail, tnc, extraMetadata };
    }
  );

  const { openModal, closeModal } = useModal()!;

  const [currentIndex, setCurrentIndex] = createSignal(0);
  let scrollContainer: HTMLDivElement | undefined;

  const handleNext = () => {
    if (currentIndex() < hubbleOneHowToRedeemDetails.length - 1) {
      setCurrentIndex((prev) => prev + 1);
      scrollCard();
    }
  };

  const handlePrevious = () => {
    if (currentIndex() > 0) {
      setCurrentIndex((prev) => prev - 1);
      scrollCard();
    }
  };

  const scrollCard = () => {
    if (scrollContainer) {
      scrollContainer.scrollTo({
        left: 300 * currentIndex() + 50 * (currentIndex() - 1),
        behavior: "smooth",
      });
    }
  };

  const hubbleOneHowToRedeemDetails = [
    {
      title:
        "Visit https://store.hubble-rewards.com/ and log in with your phone number - OTP.",
      img: hubbleOneHowToUseStep1,
    },
    {
      title: "Click on redeem code and enter your Hubble One code",
      img: hubbleOneHowToUseStep2,
    },
    {
      title: "Coins worth your gift card will be credited to your account.",
      img: hubbleOneHowToUseStep3,
    },
    {
      title:
        "Use coins to get the gift card of one or more brands from a catalogue of 450+ brands",
      img: hubbleOneHowToUseStep4,
    },
  ];

  const discountPercentage = createMemo<number>(() => {
    return (
      props.discountPercentage ??
      routeData()?.voucherDetail?.rewardDetails.find(
        (reward) => reward.rewardType === "PREPAID"
      )?.discountPercentage ??
      0
    );
  });

  return (
    <Switch>
      <Match when={routeData.state === "ready"}>
        <div
          class="flex flex-col gap-5"
          classList={{
            "m-0 w-full": props.fullScreen,
            "m-4 w-fit max-w-fit ": !props.fullScreen,
          }}
        >
          <div class="flex flex-col items-start justify-between gap-4">
            <div class="flex w-full items-start justify-between">
              <p class="text-h4 text-textDark">
                {routeData()?.voucherDetail?.name} gift card
                <Show when={discountPercentage() > 0}>
                  <>
                    {" at "}
                    <span class="text-successDark">
                      {discountPercentage()}% off
                    </span>
                  </>
                </Show>
              </p>
              <Show when={props.isInDetailedView && !props.fullScreen}>
                <div
                  class="cursor-pointer"
                  onClick={() => {
                    closeModal();
                    if (
                      props.discountPercentage != undefined &&
                      props.addToCart
                    ) {
                      openModal(() => {
                        return (
                          <DenominationDetailsModal
                            brandKey={props.brandKey}
                            discountPercentage={props.discountPercentage!}
                            addToCart={props.addToCart!}
                            addProductsToCart={props.addProductsToCart}
                            simulateErrorMessage={props.simulateErrorMessage}
                            loadingSimulateData={props.loadingSimulateData}
                            isInDetailedView={false}
                            denominationDetails={
                              props.denominationDetailsAccessor
                                ? props.denominationDetailsAccessor()
                                : props.denominationDetails
                            }
                          />
                        );
                      }, "lg:w-[360px] lg:h-[480px] h-fit rounded-[20px]");
                    }
                  }}
                >
                  <HubbleImage
                    src={collapseIcon}
                    alt="collapseIcon"
                    class="h-6 w-6"
                  />
                </div>
              </Show>
            </div>
            <Show
              when={
                routeData()?.voucherDetail?.voucherProductMetadata?.heroImageUrl
              }
              fallback={
                <div class="h-[280px] w-full">
                  <VoucherGenerationBrandCard
                    plainLogoUrl={
                      routeData()?.voucherDetail?.voucherProductMetadata
                        .plainLogoUrl ?? ""
                    }
                    cardBackgroundColor={
                      routeData()?.voucherDetail?.voucherProductMetadata
                        .cardBackgroundColor ?? ""
                    }
                    showHubbleLogo={false}
                  />
                </div>
              }
            >
              <HubbleImage
                src={
                  routeData()?.voucherDetail?.voucherProductMetadata
                    ?.heroImageUrl ??
                  routeData()?.voucherDetail?.voucherProductMetadata.logoUrl ??
                  ""
                }
                alt={`${routeData()?.voucherDetail?.voucherProductMetadata.title}`}
                class={`${props.fullScreen ? "h-[200px] " : "h-[280px] "} w-full rounded-xl`}
              />
            </Show>
          </div>
          <div
            class="flex flex-col gap-7"
            classList={{
              "w-full": props.fullScreen,
              "w-fit max-w-fit ": !props.fullScreen,
            }}
          >
            <Show
              when={
                props.brandKey === "hubble-one" &&
                routeData()?.extraMetadata.voucherDescription
              }
            >
              <div class=" flex w-full flex-col gap-2 px-1 text-medium text-textDark">
                <div class="flex items-center gap-2">
                  <p class="shrink-0 text-bold text-textDark">About</p>
                  <hr class="h-px w-full bg-baseTertiaryMedium" />
                </div>
                {routeData()?.extraMetadata.voucherDescription}
              </div>
            </Show>
            <Show when={props.brandKey === "hubble-one"}>
              <div class=" flex w-full flex-col gap-2 px-1 text-medium text-textDark">
                <div class="flex items-center gap-2">
                  <p class="shrink-0 text-bold text-textDark">Redeem on</p>
                  <hr class="h-px w-full bg-baseTertiaryMedium" />
                </div>
                All brands available on Squid
              </div>
            </Show>
            <Show
              when={
                routeData()?.voucherDetail?.voucherProductMetadata
                  .howItWorksSteps &&
                routeData()?.voucherDetail?.voucherProductMetadata
                  .howItWorksSteps.length! > 0
              }
            >
              <div class=" flex w-full flex-col gap-4 px-1">
                <div class="flex items-center gap-2">
                  <p class="shrink-0 text-bold text-textDark">How to redeem</p>
                  <hr class="h-px w-full bg-baseTertiaryMedium" />
                </div>
                <Show
                  when={props.brandKey === "hubble-one"}
                  fallback={
                    <For
                      each={
                        routeData()?.voucherDetail?.voucherProductMetadata
                          .howItWorksSteps
                      }
                    >
                      {(steps, index) => {
                        return (
                          <div class="flex items-start justify-start gap-2 text-medium">
                            <div class="flex h-[18px] w-[18px] flex-shrink-0 items-center justify-center rounded-full bg-baseTertiaryMedium  text-[10px] font-semibold text-textDark">
                              {index() + 1}
                            </div>
                            {steps.description}
                          </div>
                        );
                      }}
                    </For>
                  }
                >
                  <div class="relative">
                    <Show when={currentIndex() > 0}>
                      <div
                        class="absolute left-0 top-0 z-50 flex h-[260px] w-10 flex-col items-start justify-center bg-gradient-to-r from-white to-transparent"
                        onClick={() => {
                          handlePrevious();
                        }}
                      >
                        <div class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-lg border bg-white">
                          <PhosphorIcon
                            name="caret-left"
                            size="bold"
                            fontSize={16}
                          />
                        </div>
                      </div>
                    </Show>

                    <Show when={currentIndex() < 3}>
                      <div
                        class="absolute right-0 top-0 z-50 flex h-[260px] w-10 flex-col items-end justify-center bg-gradient-to-l from-white to-transparent"
                        onClick={() => {
                          handleNext();
                        }}
                      >
                        <div class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-lg border bg-white">
                          <PhosphorIcon
                            name="caret-right"
                            size="bold"
                            fontSize={16}
                          />
                        </div>
                      </div>
                    </Show>
                    <div
                      ref={scrollContainer}
                      class="no-scrollbar flex w-min gap-4 overflow-x-scroll scroll-smooth"
                      classList={{
                        "max-w-full": props.fullScreen,
                        "max-w-[470px]": !props.fullScreen,
                      }}
                      onScroll={(e) => {
                        const scrollLeft = e.currentTarget.scrollLeft;
                        const scrollWidth = e.currentTarget.scrollWidth;
                        const containerMaxVisibleWidth = 268;
                        const scrollRight =
                          scrollWidth - scrollLeft - containerMaxVisibleWidth;
                        if (scrollLeft === 0) {
                          setCurrentIndex(0);
                        } else if (scrollRight === 0) {
                          setCurrentIndex(3);
                        } else {
                          setCurrentIndex(
                            Math.floor(scrollLeft / containerMaxVisibleWidth)
                          );
                        }
                      }}
                    >
                      <For each={hubbleOneHowToRedeemDetails}>
                        {(step, index) => {
                          return (
                            <div class="flex w-[354px] flex-shrink-0 flex-col">
                              <div class="rounded-xl">
                                <HubbleImage
                                  src={step.img}
                                  alt=""
                                  class="h-[256px] w-[354px]"
                                  imageBorderStyleClass="rounded-xl  border"
                                />
                              </div>
                              <div class="mt-2.5 flex w-full max-w-[354px] items-start justify-start gap-2 text-medium">
                                <div class="flex h-[18px] w-[18px] flex-shrink-0 items-center justify-center rounded-full bg-baseTertiaryMedium  text-[10px] font-semibold text-textDark">
                                  {index() + 1}
                                </div>
                                <div class="w-full">
                                  <SolidMarkdown>{step.title}</SolidMarkdown>
                                </div>
                              </div>
                            </div>
                          );
                        }}
                      </For>
                    </div>
                  </div>
                </Show>
              </div>
            </Show>
            <Show when={routeData()?.tnc != ""}>
              <div
                class="flex w-full flex-col gap-4 px-1"
                classList={{
                  "mb-8": props.fullScreen,
                }}
              >
                <div class="flex w-full items-center gap-2">
                  <p class="shrink-0 text-bold text-textDark">Things to note</p>
                  <hr class="h-px w-full bg-baseTertiaryMedium" />
                </div>

                <For each={routeData()?.tnc.split("\n")}>
                  {(tnc, index) => {
                    return (
                      <div class="flex w-full items-start justify-start gap-2 text-medium">
                        <div class="flex h-[18px] w-[18px] flex-shrink-0 items-center justify-center rounded-full bg-baseTertiaryMedium  text-[10px] font-semibold text-textDark">
                          {index() + 1}
                        </div>
                        <SolidMarkdown>{tnc.replace("- ", "")}</SolidMarkdown>
                      </div>
                    );
                  }}
                </For>
              </div>
            </Show>
          </div>
        </div>
      </Match>
      <Match when={true}>
        <div class="flex-1 items-center justify-center">
          <DottedLoader
            color="#999"
            from="brand details modal - match route data not ready"
          />
        </div>
      </Match>
    </Switch>
  );
}
